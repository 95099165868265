import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from '../../../helpers';


@Component({
  selector: "app-tooltips",
  templateUrl: "./tooltips.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class TooltipsComponent implements OnInit {


  constructor() {

  }
  ngOnInit() {

  }

}
