import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

declare let mLayout: any;
@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
  permiteAcesso = false;

  constructor(private oauthService: OAuthService) {

  }
  ngOnInit() {
    let grupos: Array<string> = [];
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) { return null; }
    grupos = JSON.parse(claims.grupos);
    if (grupos.some(g => g.toLowerCase() === 'ccf60381-d228-4e38-bf74-494524d88079')) {
      this.permiteAcesso = true;
    }

  }
  ngAfterViewInit() {

    mLayout.initHeader();

  }

}
