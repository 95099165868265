import { Component, OnInit, HostBinding, Input, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'aside-page',
  templateUrl: './aside-page.component.html',
  styleUrls: ['./aside-page.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('*',
        style({
          opacity: 1,
          transform: 'translateX(0)'
        })
      ),
      transition(':enter', [
        style({
          transform: 'translateX(100%)'
        }),
        animate('0.2s ease-in')
      ]),
      transition(':leave', [
        animate('0.2s ease-out', style({
          transform: 'translateX(100%)'
        }))
      ])
    ])
  ]
})

export class AsidePageComponent implements OnInit {
  @HostBinding('@slideInOut') routeAnimation = false;
  @HostBinding('style.display') display = 'block';
  @HostBinding('style.position') position = 'absolute';
  @Input() titulo:string = '';
  @Output() fechar = new EventEmitter<boolean>();

  constructor() {
    }

  ngOnInit() {    
  }

  close(){
      this.fechar.emit(false);
  }
}