import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SearchPipe } from './pipes/search.pipe';
import { AsidePageComponent } from './components/aside-page/aside-page.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UsuarioService } from './services/usuario.service';

import { OrderBy } from './pipes/orderby.pipe';

import { SorterComponent } from './components/sorter/sorter.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import { PaginationComponent } from './components/datatable/pagination/pagination.component';
import { PaginationPipe } from './pipes/pagination.pipe';
import { MessageService } from './services/message.service';
import { MessagesComponent } from './components/messages/messages.component';
import { GrowlModule } from 'primeng/primeng';
import { ServidorService } from './services/servidor.service';


@NgModule({
  imports: [CommonModule, FormsModule, GrowlModule],
  exports: [
    BreadcrumbComponent,
    SearchPipe,
    AsidePageComponent,
    LoaderComponent,
    OrderBy,
    SorterComponent,
    PaginationComponent,
    PaginationPipe,
    DatatableComponent,
    MessagesComponent
  ],
  declarations: [
    BreadcrumbComponent,
    SearchPipe,
    AsidePageComponent,
    LoaderComponent,
    OrderBy,
    SorterComponent,
    PaginationComponent,
    PaginationPipe,
    DatatableComponent,
    MessagesComponent
  ],
  providers: [UsuarioService, MessageService, ServidorService]
})
export class SharedModule { }
