import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paginate',
    pure: false
})

export class PaginationPipe implements PipeTransform {

    transform(value: any, args: any): any {
        let count: number = value.length;
        let page: number = args.page;
        let perPage: number = +args.perPage;
        let dataDisplay = [];
        let i: number = (page - 1) * perPage;
        let limite: number = i;
        for (i; (i < (limite + perPage)) && (i < count); i++) {
            dataDisplay.push(value[i]);
        }
        return dataDisplay;
    }
}