import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'default-sorter',
    templateUrl:'./sorter.component.html',
    styleUrls: ['./sorter.component.css']
    // template: `
    // <a class="text-nowrap" (click)="sorter()" title="{{dataSource.nome}}">
    //     <i [hidden]="sort != 'asc' || currentSort != dataSource.chave" class="fa fa-arrow-down"> </i>
    //     <i [hidden]="sort != 'desc' || currentSort != dataSource.chave" class="fa fa-arrow-up"> </i>
    //     {{dataSource.nome}}
    // </a>
    // `
})

export class SorterComponent {  
    @Input() column: any;
    @Input() sort: any;
    selectedClass(columnName): string{
        return columnName == this.sort.column ? 'sort-' + this.sort.descending : '';
      }
      
      changeSorting(columnName): void{
        var sort = this.sort;
        if (sort.column == columnName) {
          sort.descending = !sort.descending;
        } else {
          sort.column = columnName;
          sort.descending = false;
        }
      }
      
      convertSorting(): string{
        return this.sort.descending ? '-' + this.sort.column : this.sort.column;
      }
   
}