import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from '../../../helpers';


@Component({
  selector: "app-scroll-top",
  templateUrl: "./scroll-top.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ScrollTopComponent implements OnInit {


  constructor() {

  }
  ngOnInit() {

  }

}
