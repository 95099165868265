// This api will come in the next version
import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'portal_sistemas_bischoff',

  // Url of the Identity Provider
  issuer: 'https://sso.bischoffgroup.com.br',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + "/",  
  silentRefreshRedirectUri: window.location.origin + "/silent-refresh.html",

  oidc: true,
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile api1',
  loginUrl: 'https://sso.bischoffgroup.com.br/connect/authorize'
}