import { Component, AfterViewInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'breadcrumb',
    templateUrl: 'breadcrumb.component.html'
})

export class BreadcrumbComponent implements AfterViewInit {
    @Input() caminhos = [];

    constructor(private router: Router) { }

    ngAfterViewInit() {
        this.setPath()
    }

    setPath() {
        for (let i = 0; i <= this.caminhos.length; i++) {
            if (this.isOdd(i)) {
                this.caminhos.splice(i, 0, { titulo: "-", link: '' });
            }
        }
    }

    getTitulo(): string {
        if (this.isOdd(this.caminhos.length))
            return this.caminhos[this.caminhos.length - 1].titulo
        return this.caminhos[this.caminhos.length - 2].titulo
    }

    goto(link) {
        this.router.navigate([link]);
    }

    isOdd(num) {
        return num % 2
    }
}