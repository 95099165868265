import { Component, AfterViewInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-user-info',
    templateUrl: 'user-info.component.html'
})

export class UserInfoComponent implements AfterViewInit {
    userId: string;
    name = '';
    userName = '';
    perfilImg = '';
    permiteAcesso = false;

    constructor(private oauthService: OAuthService) { }

    ngAfterViewInit() {
        this.getClaims();
    }

    getClaims() {
        const claims: any = this.oauthService.getIdentityClaims();
        if (!claims) { return null; }
        this.name = claims.full_name;
        this.userName = claims.preferred_username;
        this.perfilImg = claims.avatar_image;
        this.userId = claims.sub;
    }

    logOut() {
        this.oauthService.logOut();
        window.location.href = 'https://sso.bischoffgroup.com.br/Account/Logout';
    }

    getPerfilLink(): string {
        return 'https://sso.bischoffgroup.com.br/Account/UserProfile?id=' + this.userId;
    }
}
