import { Injectable } from "@angular/core";
import { Http, Response } from "@angular/http";
import "rxjs/add/operator/map";
import { OAuthService } from "angular-oauth2-oidc";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class AuthenticationService {

  constructor(private oauthService: OAuthService, private _router: Router) {
  }

  login() {     
    debugger  
 var hasIdToken = this.oauthService.hasValidIdToken();
    var hasAccessToken = this.oauthService.hasValidAccessToken();

    if (hasIdToken && hasAccessToken) {      
      this._router.navigate(["/index"]);
    }
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }
}
