import { Component, Input } from '@angular/core';

@Component({
    selector: 'jb-datatable',
    templateUrl: 'datatable.component.html'
})

export class DatatableComponent {
    @Input() columns: any[];
    @Input() data: any[];
    @Input() sort: any;

    selectedClass(columnName): string{
        return columnName == this.sort.column ? 'sort-' + this.sort.descending : '';
      }
      
      changeSorting(columnName): void{
        var sort = this.sort;
        if (sort.column == columnName) {
          sort.descending = !sort.descending;
        } else {
          sort.column = columnName;
          sort.descending = false;
        }
      }
      
      convertSorting(): string{
        return this.sort.descending ? '-' + this.sort.column : this.sort.column;
      }
}