import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Helpers } from './helpers';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from './auth/auth.config';


@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnInit {
  title = 'app';
  globalBodyClass = `m-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile
  m-aside-left--skin-light m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default`;

  constructor(private _router: Router, private oauthService: OAuthService) {

    this.configureAuth();

  }

  ngOnInit() {
    this._router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        Helpers.setLoading(true);
        Helpers.bodyClass(this.globalBodyClass);
      }
      if (route instanceof NavigationEnd) {
        Helpers.setLoading(false);
      }
    });
  }

  private configureAuth() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin()
      .then(r => {
        const path = location.hash.replace('#', '');
        this._router.navigateByUrl(path);

      });

    // this
    //   .oauthService
    //   .events
    //   .filter(e => e.type == 'token_received')
    //   .subscribe(e => {
    //   });

    this
      .oauthService
      .events
      .subscribe(e => {
        if (e.type === 'token_expires') {
          this.oauthService.setupAutomaticSilentRefresh();
        }
      });
  }
}
