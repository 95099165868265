import { Component, Input, EventEmitter, Output, AfterViewInit, OnChanges, SimpleChange, IterableDiffers, DoCheck } from '@angular/core';

@Component({
    selector: 'jb-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements AfterViewInit, OnChanges {
    private perPage: number = 10
    private page: number = 1;
    @Input() pagesToShow: number;
    @Input() dataSource: Array<any>;

    @Output() perPageChange = new EventEmitter<number>();
    @Output() pageChange = new EventEmitter<number>();
    loading: boolean = false;
    count: number = 1;
    dataDisplay: Array<any> = [];
    differ: any;

    constructor(differs: IterableDiffers) {
        this.differ = differs.find([]).create(null)
    }

    ngAfterViewInit() {
        this.count = this.dataSource.length;
        this.onPage(this.page);
    }

    ngOnChanges(change) {
        this.count = this.dataSource.length;
        if (this.perPage >= this.count) {
            this.onPage(1);
        }
    }

    getMin(): number {
        return ((this.perPage * this.page) - this.perPage) + 1;
    }

    getMax(): number {
        let max = this.perPage * this.page;
        if (max > this.count) {
            max = this.count;
        }
        return max;
    }

    onPage(n: number): void {
        this.page = n;
        this.pageChange.emit(n);
    }

    onPrev(): void {
        if (this.page > 1)
            this.onPage(this.page - 1);
    }

    onNext(next: boolean): void {
        if (!this.lastPage())
            this.onPage(this.page + 1);
    }

    totalPages(): number {
        return Math.ceil(this.count / this.perPage) || 0;
    }

    lastPage(): boolean {
        return this.perPage * this.page > this.count;
    }

    setPerPage(p) {        
        this.perPage = p;
        this.onPage(1);
        this.perPageChange.emit(p);
    }

    getPages(): number[] {
        const c = Math.ceil(this.count / this.perPage);
        const p = this.page || 1;
        const pagesToShow = this.pagesToShow || 9;
        const pages: number[] = [];
        pages.push(p);
        const times = pagesToShow - 1;
        for (let i = 0; i < times; i++) {
            if (pages.length < pagesToShow) {
                if (Math.min.apply(null, pages) > 1) {
                    pages.push(Math.min.apply(null, pages) - 1);
                }
            }
            if (pages.length < pagesToShow) {
                if (Math.max.apply(null, pages) < c) {
                    pages.push(Math.max.apply(null, pages) + 1);
                }
            }
        }
        pages.sort((a, b) => a - b);
        return pages;
    }

    temMaisPaginasDepois(): boolean {
        if ((this.totalPages() > this.pagesToShow) && ((this.totalPages() - (this.page - (Math.ceil(this.pagesToShow / 2)))) >= this.pagesToShow))
            return true
        return false
    }

    temMaisPaginasAntes() {
        let p = Math.ceil(this.pagesToShow / 2);
        if ((this.page - p) >= 1)
            return true
        return false
    }

    avancarPaginas() {
        if ((this.totalPages() - this.page) >= this.pagesToShow) {
            this.onPage(this.page + this.pagesToShow)
        } else {
            this.onPage(this.totalPages())
        }
    }

    retornarPaginas() {
        if ((this.page - this.pagesToShow) > 0) {
            this.onPage(this.page - this.pagesToShow)
        } else {
            this.onPage(1)
        }
    }
}