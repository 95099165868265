import { Injectable } from '@angular/core';
import { RequestOptions, Headers } from '@angular/http';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class ServidorService {

    constructor(private oAuthService: OAuthService) { }


    options(): RequestOptions {
        const headers = new Headers({
            'Authorization': 'Bearer ' + this.oAuthService.getAccessToken(),
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
        return new RequestOptions({ headers: headers });
    }

    sso(): string {
        return 'https://sso.bischoffgroup.com.br/Api/';
    }

    sistemas(): string {
        return 'https://sistemas.bischoffgroup.com.br/Api/';
    }
}
