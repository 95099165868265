import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: ThemeComponent,
    children: [
      {
        path: 'index',
        canActivate: [AuthGuard],
        loadChildren: '../custom/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: '../custom/admin/admin.module#AdminModule'
      },
      {
        path: '404',
        loadChildren: './pages/default/not-found/not-found/not-found.module#NotFoundModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ThemeRoutingModule { }
