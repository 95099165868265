import { Injectable } from '@angular/core';
import { Message } from 'primeng/components/common/api';

@Injectable()
export class MessageService {
  messages: Message[] = [];

  add(tipo: string, titulo: string, mensagem: string) {
    this.messages = [];
    this.messages.push({ severity: tipo, summary: titulo, detail: mensagem });
  }

  clear() {
    this.messages = [];
  }
}
