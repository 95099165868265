import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from '../../../helpers';


@Component({
  selector: "app-quick-sidebar",
  templateUrl: "./quick-sidebar.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class QuickSidebarComponent implements OnInit {


  constructor() {

  }
  ngOnInit() {

  }

}
