import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

@Component({
    selector: 'loader-block',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.css']
})

export class LoaderComponent implements OnInit, AfterViewInit {
    classe: string = '';
    @Input() loading: boolean = false;
    constructor() { }

    ngOnInit() { }

    ngAfterViewInit() {
        this.classe = 'overlay-block'
    }
}