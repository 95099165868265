import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";

import { AuthRoutingModule } from "./auth-routing.routing";
import { AlertComponent } from "./_directives/alert.component";
import { AuthGuard } from "./_guards/auth.guard";


@NgModule({
  declarations: [AlertComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    AuthRoutingModule,
  ],
  providers: [
    AuthGuard
  ]
})

export class AuthModule {
}
