import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { ServidorService } from './servidor.service';


@Injectable()
export class UsuarioService {

    constructor(
        private http: Http,
        private servidorService: ServidorService
    ) { }

    getUsuarios() {
        return this.http.get(this.servidorService.sso() + 'usuarios/buscartodos', this.servidorService.options())
            .map(response => response.json());
    }

    // putUsuarioGrupo(grupoId: string, usuario: Usuario) {
    //     // return this.http.put(this.urlBase + 'alterar', grupo, this.options)
    //     return this.http.post(this.urlMock + grupoId + '/usuarios', usuario)// <-- MOCK
    //         .map(response => response.json())
    // }

    // removeUsuarioGrupo(usuarioId: string, grupoId: string){
    //     // return this.http.delete(this.urlBase + 'remover', grupo, this.options)
    //     return this.http.delete(this.urlMock + grupoId + '/usuarios/'+ usuarioId)// <-- MOCK
    //         .map(() => {})
    // }
}
